// src/components/LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('csrf_token');
    localStorage.removeItem('csrf_token_id');
    navigate('/login');
  };

  return (
    <Button onClick={handleLogout} variant="outlined" sx={{color:'rgb(146, 147, 148)' ,border:'1px solid rgb(146, 147, 148)'}}>
      Logout
    </Button>
  );
};

export default LogoutButton;
