import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  IconButton,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Chip
} from '@mui/material';
import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import logo from 'assets/images/LogoAina.svg';
import CustomTable from './customtable';
import { useNavigate } from 'react-router-dom';
import SimpleSnackbar from './SimpleSnackbar'; // Importa tu Snackbar personalizado
import LogoutButton from './LogoutButton'; // Importa el botón de logout


const Dashboard = ({ token }) => {
  const navigate = useNavigate();  // Asegúrate de que `navigate` está declarado correctamente
  const [message, setMessage] = useState('');

  
  const sessionTimeout = 60 * 60 * 1000; // 1 hora en milisegundos
  const warningTime = 5 * 60 * 1000; // 5 minutos en milisegundos

  useEffect(() => {
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    
    if (!sessionStartTime) {
      const now = new Date().getTime();
      localStorage.setItem('sessionStartTime', now);
    }

    const calculateRemainingTime = () => {
      const now = new Date().getTime();
      const sessionStartTime = parseInt(localStorage.getItem('sessionStartTime'), 10);
      return sessionTimeout - (now - sessionStartTime);
    };

    const remainingTime = calculateRemainingTime();

    if (remainingTime <= 0) {
      localStorage.removeItem('token');
      localStorage.removeItem('sessionStartTime');
      navigate('/login');
      return;
    }

    const timer = setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('sessionStartTime');
      navigate('/login');
    }, remainingTime);

    const warningTimer = setTimeout(() => {
      setMessage('La sesión expirará en 5 minutos. Por favor, guarda tus cambios y vuelve a iniciar sesión.');
    }, remainingTime - warningTime);

    return () => {
      clearTimeout(timer);
      clearTimeout(warningTimer);
    };
  }, [navigate]);


  const [data, setData] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [section, setSection] = useState('vehiculos');
  const [newItem, setNewItem] = useState({
    categoria: '',
    segmento: '',
    nombre: '',
    caracteristicas: {
      plazas: '',
      maletas: '',
      combustible: '',
      transmision: '',
      etiqueta: ''
    },
    equipamiento: [],
    remolque: false,
    carga_medidas: false,
    informacion_remolque: {
      carnet: '',
      peso_vehiculo: '',
      masa_maxima_admisible: '',
      arrastre_sin_freno: '',
      arrastre_con_freno: ''
    },
    informacion_carga: {
      largo: '',
      ancho: '',
      alto: '',
      volumen: '',
      capacidad_carga: ''
    },
    link_reserva: ''
  });
  const [newItemImage, setNewItemImage] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [editItemImage, setEditItemImage] = useState(null);
  const [newEquipamiento, setNewEquipamiento] = useState('');
  const [newCliente, setNewCliente] = useState({ nombre: '', tel: '', mail: '', message: '' });
  const [editClienteId, setEditClienteId] = useState(null);
  const [editCliente, setEditCliente] = useState(null);
  const [clientesDTO, setClientesDTO] = useState([]);
  const [newClienteDTO, setNewClienteDTO] = useState({ nombre: '', tel: '', mail: '', message: '' });
  const [editClienteDTOId, setEditClienteDTOId] = useState(null);
  const [editClienteDTO, setEditClienteDTO] = useState(null);

  const [updateTrigger, setUpdateTrigger] = useState(0);


  const csrfToken = localStorage.getItem('csrf_token');
  const csrfTokenId = localStorage.getItem('csrf_token_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/protected/data', {
          headers: {
            'x-access-tokens': token,
            'x-csrf-token': csrfToken,
            'x-csrf-token-id': csrfTokenId,
          },
        });
        setData(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error al obtener los datos:', error);
        }
      }
    };
    fetchData();
  }, [token, csrfToken, csrfTokenId, updateTrigger]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await axios.get('/api/clientes', {
          headers: {
            'x-access-tokens': token,
            'x-csrf-token': csrfToken,
            'x-csrf-token-id': csrfTokenId,
          },
        });
        setClientes(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error al obtener los datos de clientes:', error);
        }
      }
    };
    fetchClientes();
  }, [token, csrfToken, csrfTokenId,, updateTrigger]);

  useEffect(() => {
    const fetchClientesDTO = async () => {
      try {
        const response = await axios.get('/api/dto', {
          headers: {
            'x-access-tokens': token,
            'x-csrf-token': csrfToken,
            'x-csrf-token-id': csrfTokenId,
          },
        });
        setClientesDTO(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error al obtener los datos de clientesDTO:', error);
        }
      }
    };
    fetchClientesDTO();
  }, [token, csrfToken, csrfTokenId]);

  const handleImageChange = (e) => {
    setNewItemImage(e.target.files[0]);
  };

  const handleEditImageChange = (e) => {
    setEditItemImage(e.target.files[0]);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
  if (section === 'vehiculos') {
    const formData = new FormData();
    formData.append('data', JSON.stringify(newItem));
    if (newItemImage) {
      formData.append('image', newItemImage);
    }
    try {
      const response = await axios.post('/api/protected/data', formData, {
        headers: {
          'x-access-tokens': token,
          'x-csrf-token': csrfToken,
          'x-csrf-token-id': csrfTokenId,
          'Content-Type': 'multipart/form-data',
        },
      });
      // Actualizar los datos
      const newData = [...data, response.data];
      setData(newData);

      // Resetear el estado para poder editar el nuevo vehículo inmediatamente
      setNewItem({
        categoria: '',
        segmento: '',
        nombre: '',
        caracteristicas: {
          plazas: '',
          maletas: '',
          combustible: '',
          transmision: '',
          etiqueta: ''
        },
        equipamiento: [],
        remolque: false,
        carga_medidas: false,
        informacion_remolque: {
          carnet: '',
          peso_vehiculo: '',
          masa_maxima_admisible: '',
          arrastre_sin_freno: '',
          arrastre_con_freno: ''
        },
        informacion_carga: {
          largo: '',
          ancho: '',
          alto: '',
          volumen: '',
          capacidad_carga: ''
        },
        link_reserva: ''
      });
      setNewItemImage(null);
      setUpdateTrigger(updateTrigger + 1);
      // Permitir edición inmediata del nuevo vehículo
      handleEditClick(response.data.id, response.data);

    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Error al agregar el elemento:', error);
      }
    }
    } else if (section === 'clientes') {
      try {
        const response = await axios.post('/api/clientes', newCliente, {
          headers: {
            'x-access-tokens': token,
            'x-csrf-token': csrfToken,
            'x-csrf-token-id': csrfTokenId,
          },
        });
        setClientes([...clientes, response.data]);
        setNewCliente({ nombre: '', tel: '', mail: '', message: '' });
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error al agregar el cliente:', error);
        }
      }
    } else if (section === 'clientesDTO') {
      try {
        const response = await axios.post('/api/dto', newClienteDTO, {
          headers: {
            'x-access-tokens': token,
            'x-csrf-token': csrfToken,
            'x-csrf-token-id': csrfTokenId,
          },
        });
        setClientesDTO([...clientesDTO, response.data]);
        setNewClienteDTO({ nombre: '', tel: '', mail: '', message: '' });
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error al agregar el clienteDTO:', error);
        }
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/${section === 'vehiculos' ? 'protected/data' : section === 'clientes' ? 'clientes' : 'dto'}/${id}`, {
        headers: {
          'x-access-tokens': token,
          'x-csrf-token': csrfToken,
          'x-csrf-token-id': csrfTokenId,
        },
      });
      if (section === 'vehiculos') {
        setData(data.filter(item => item.id !== id));
      } else if (section === 'clientes') {
        setClientes(clientes.filter(cliente => cliente.id !== id));
      } else if (section === 'clientesDTO') {
        setClientesDTO(clientesDTO.filter(clienteDTO => clienteDTO.id !== id));
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Error al eliminar el elemento:', error);
      }
    }
  };

  const handleEdit = async (id) => {
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(editItem));
      if (editItemImage) {
        formData.append('image', editItemImage);
      }
      const response = await axios.put(`/api/${section === 'vehiculos' ? 'protected/data' : section === 'clientes' ? 'clientes' : 'dto'}/${id}`, formData, {
        headers: {
          'x-access-tokens': token,
          'x-csrf-token': csrfToken,
          'x-csrf-token-id': csrfTokenId,
        },
      });
      if (section === 'vehiculos') {
        setData(data.map(item => (item.id === id ? response.data : item)));
      } else if (section === 'clientes') {
        setClientes(clientes.map(cliente => (cliente.id === id ? response.data : cliente)));
      } else if (section === 'clientesDTO') {
        setClientesDTO(clientesDTO.map(clienteDTO => (clienteDTO.id === id ? response.data : clienteDTO)));
      }
      setUpdateTrigger(updateTrigger + 1);
      setEditItemId(null);
      setEditItem(null);
      setEditItemImage(null);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Error al editar el elemento:', error);
      }
    }
  };

  const handleEditClick = (id, item) => {
    // Resetear el estado de edición
    setUpdateTrigger(updateTrigger + 1);
    setEditItemId(null);
    setEditItem(null);
    setEditItemImage(null);
  
    // Configurar el nuevo item para edición
    setTimeout(() => {
      setEditItemId(id);
      setEditItem({
        categoria: '',
        segmento: '',
        nombre: '',
        caracteristicas: {
          plazas: '',
          maletas: '',
          combustible: '',
          transmision: '',
          etiqueta: ''
        },
        equipamiento: [],
        remolque: false,
        carga_medidas: false,
        informacion_remolque: {
          carnet: '',
          peso_vehiculo: '',
          masa_maxima_admisible: '',
          arrastre_sin_freno: '',
          arrastre_con_freno: ''
        },
        informacion_carga: {
          largo: '',
          ancho: '',
          alto: '',
          volumen: '',
          capacidad_carga: ''
        },
        link_reserva: '',
        ...item,
        caracteristicas: { ...item.caracteristicas },
        equipamiento: [...item.equipamiento],
        informacion_remolque: { ...item.informacion_remolque },
        informacion_carga: { ...item.informacion_carga }
      });
    }, 0);
  };
  
  const handleEquipamientoAdd = () => {
    if (editItemId && editItem) {
      setEditItem({
        ...editItem,
        equipamiento: [...editItem.equipamiento, newEquipamiento]
      });
    } else {
      setNewItem({
        ...newItem,
        equipamiento: [...newItem.equipamiento, newEquipamiento]
      });
    }
    setNewEquipamiento('');
  };

  const handleEquipamientoDelete = (index) => {
    if (editItemId && editItem) {
      const updatedEquipamiento = editItem.equipamiento.filter((_, i) => i !== index);
      setEditItem({
        ...editItem,
        equipamiento: updatedEquipamiento
      });
    } else {
      const updatedEquipamiento = newItem.equipamiento.filter((_, i) => i !== index);
      setNewItem({
        ...newItem,
        equipamiento: updatedEquipamiento
      });
    }
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
    setEditItem(null);
    setEditItemImage(null);
  };

  const clienteColumns = [
    { headerName: 'Nombre', field: 'nombre' },
    { headerName: 'Teléfono', field: 'tel' },
    { headerName: 'Email', field: 'mail' },
    { headerName: 'Acciones', field: 'acciones' },
  ];

  const clienteTableData = clientes.map(cliente => ({
    ...cliente,
    acciones: (
      <Box>
        <IconButton onClick={() => handleEditClick(cliente.id, cliente)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => handleDelete(cliente.id)}>
          <Delete />
        </IconButton>
      </Box>
    )
  }));

  const vehicleColumns = [
    { headerName: 'Nombre', field: 'nombre' },
    { headerName: 'Categoría', field: 'categoria' },
    { headerName: 'Imagen', field: 'imagen' },
    { headerName: 'Remolque', field: 'remolque' },
    { headerName: 'Acciones', field: 'acciones' },
  ];

  const vehicleTableData = data.map(item => ({
    ...item,
    acciones: (
      <Box>
        <IconButton onClick={() => handleEditClick(item.id, item)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => handleDelete(item.id)}>
          <Delete />
        </IconButton>
      </Box>
    ),
    imagen: item.imagen ? <img src={item.imagen} alt={item.nombre} style={{ width: '100px' }} /> : 'No Image'
  }));

  const clienteDTOColumns = [
    { headerName: 'Nombre', field: 'nombre' },
    { headerName: 'Teléfono', field: 'tel' },
    { headerName: 'Email', field: 'mail' },
    { headerName: 'Acciones', field: 'acciones' },
  ];

  const clienteDTOTableData = clientesDTO.map(clienteDTO => ({
    ...clienteDTO,
    acciones: (
      <Box>
        <IconButton onClick={() => handleEditClick(clienteDTO.id, clienteDTO)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => handleDelete(clienteDTO.id)}>
          <Delete />
        </IconButton>
      </Box>
    )
  }));

  return (
    <>
    <Box>
      {/* Botón de logout en la esquina superior derecha */}
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <LogoutButton />
      </Box>

      {/* Renderiza tu contenido aquí */}
      <SimpleSnackbar message={message} />
    </Box>
    <Box sx={{ display: 'flex' }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item lg={2}>
          <Box sx={{ height: '100%', width: '100%', padding: '10px' }}>
            <Box
              sx={{
                backgroundColor: '#f44336',
                height: '70px',
                width: '90%',
                borderRadius: '12px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{ height: '45px' }}
              />
            </Box>
            <Box sx={{ backgroundColor: '#FFFFFF', height: '80%', width: '90%', borderRadius: '12px', padding: '15px' }}>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: 'rgb(240, 242, 245)',
                  color: 'rgb(85, 85, 85)',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'rgb(220, 222, 225)',
                    boxShadow: 'none',
                  }
                }}
                onClick={() => setSection('vehiculos')}
              >
                Vehiculos
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  marginTop: '15px',
                  backgroundColor: 'rgb(240, 242, 245)',
                  color: 'rgb(85, 85, 85)',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'rgb(220, 222, 225)',
                    boxShadow: 'none',
                  }
                }}
                onClick={() => setSection('clientes')}
              >
                Clientes
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  marginTop: '15px',
                  backgroundColor: 'rgb(240, 242, 245)',
                  color: 'rgb(85, 85, 85)',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'rgb(220, 222, 225)',
                    boxShadow: 'none',
                  }
                }}
                onClick={() => setSection('clientesDTO')}
              >
                ClientesDTO
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={10}>
          <Container maxWidth="md">
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <Box sx={{mb: 4}}>
              <Typography variant="h4" gutterBottom >
                AinaCar - {section.charAt(0).toUpperCase() + section.slice(1)}
              </Typography>
              </Box>
              {section === 'vehiculos' && (
                <>
                  <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '15px' }}>
                    <Accordion sx={{ boxShadow: 'none' }}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Añadir vehículo</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ mb: 4, width: '100%', height: '100%' }}>
                          <FormControl fullWidth sx={{ mb: 2, minHeight: '48px' }}>
                            <InputLabel sx={{ minHeight: '48px' }}>Categoría</InputLabel>
                            <Select
                              value={newItem.categoria}
                              onChange={(e) => setNewItem({ ...newItem, categoria: e.target.value })}
                              label="Categoría"
                              sx={{ minHeight: '52px' }}
                            >
                              <MenuItem value=""><em>Ninguna</em></MenuItem>
                              <MenuItem value="Coches - SUV">Coches - SUV</MenuItem>
                              <MenuItem value="Furgonetas pasajeros">Furgonetas pasajeros</MenuItem>
                              <MenuItem value="Furgones y camiones">Furgones y camiones</MenuItem>
                              <MenuItem value="Furgones y camiones">Vehículos con bola de remolque</MenuItem>
                            </Select>
                          </FormControl>

                          <TextField
                            fullWidth
                            label="Segmento"
                            variant="outlined"
                            value={newItem.segmento}
                            onChange={(e) => setNewItem({ ...newItem, segmento: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Nombre"
                            variant="outlined"
                            value={newItem.nombre}
                            onChange={(e) => setNewItem({ ...newItem, nombre: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Parte 1 del Link de Karve (category ID)"
                            variant="outlined"
                            value={newItem.parte1}
                            onChange={(e) => {
                              const parte1 = e.target.value;
                              const link_reserva = `&initial-category-filter-ids=${parte1}&force-car-type-id=${newItem.parte2}`;
                              setNewItem({ ...newItem, parte1, link_reserva });
                            }}
                            sx={{ mb: 2 }}
                          />

                          <TextField
                            fullWidth
                            label="Parte 2 del Link de karve (car type ID)"
                            variant="outlined"
                            value={newItem.parte2}
                            onChange={(e) => {
                              const parte2 = e.target.value;
                              const link_reserva = `&initial-category-filter-ids=${newItem.parte1}&force-car-type-id=${parte2}`;
                              setNewItem({ ...newItem, parte2, link_reserva });
                            }}
                            sx={{ mb: 2 }}
                          />

                          <TextField
                            fullWidth
                            label="Link de Reserva en Karve"
                            variant="outlined"
                            value={newItem.link_reserva}
                            onChange={(e) => setNewItem({ ...newItem, link_reserva: e.target.value })}
                            sx={{ mb: 2 }}
                            disabled // Este campo está deshabilitado para evitar edición directa
                          />


                          {/* Características */}
                          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Características</Typography>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Plazas"
                                variant="outlined"
                                value={newItem.caracteristicas.plazas}
                                onChange={(e) => setNewItem({ ...newItem, caracteristicas: { ...newItem.caracteristicas, plazas: e.target.value } })}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Maletas"
                                variant="outlined"
                                value={newItem.caracteristicas.maletas}
                                onChange={(e) => setNewItem({ ...newItem, caracteristicas: { ...newItem.caracteristicas, maletas: e.target.value } })}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Combustible"
                                variant="outlined"
                                value={newItem.caracteristicas.combustible}
                                onChange={(e) => setNewItem({ ...newItem, caracteristicas: { ...newItem.caracteristicas, combustible: e.target.value } })}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Transmisión"
                                variant="outlined"
                                value={newItem.caracteristicas.transmision}
                                onChange={(e) => setNewItem({ ...newItem, caracteristicas: { ...newItem.caracteristicas, transmision: e.target.value } })}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Etiqueta"
                                variant="outlined"
                                value={newItem.caracteristicas.etiqueta}
                                onChange={(e) => setNewItem({ ...newItem, caracteristicas: { ...newItem.caracteristicas, etiqueta: e.target.value } })}
                              />
                            </Grid>
                          </Grid>

                          {/* Equipamiento */}
                          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Equipamiento</Typography>
                          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                            <Grid item xs={10}>
                              <TextField
                                fullWidth
                                label="Añadir equipamiento"
                                variant="outlined"
                                value={newEquipamiento}
                                onChange={(e) => setNewEquipamiento(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{ color: '#FFFFFF' }} fullWidth variant="contained" color="primary" onClick={handleEquipamientoAdd}>
                                Añadir
                              </Button>
                            </Grid>
                          </Grid>
                          <ul>
                            {newItem.equipamiento.map((eq, index) => (
                              <li key={index}>
                                {eq}
                                <IconButton size="small" onClick={() => handleEquipamientoDelete(index)}>
                                  <Delete />
                                </IconButton>
                              </li>
                            ))}
                          </ul>

                          {/* Checkbox de Remolque */}
                          <FormControlLabel
                            sx={{ mt: 4 }}
                            control={
                              <Checkbox
                                checked={newItem.remolque}
                                onChange={(e) => setNewItem({ ...newItem, remolque: e.target.checked })}
                              />
                            }
                            label="¿Lleva remolque?"
                          />

                          {/* Campos de información de remolque */}
                          {newItem.remolque && (
                            <Box>
                              <TextField
                                fullWidth
                                label="Carnet necesario"
                                variant="outlined"
                                value={newItem.informacion_remolque.carnet}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_remolque: { ...newItem.informacion_remolque, carnet: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Peso del vehículo"
                                variant="outlined"
                                value={newItem.informacion_remolque.peso_vehiculo}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_remolque: { ...newItem.informacion_remolque, peso_vehiculo: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Masa máxima admisible"
                                variant="outlined"
                                value={newItem.informacion_remolque.masa_maxima_admisible}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_remolque: { ...newItem.informacion_remolque, masa_maxima_admisible: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Arrastre sin freno"
                                variant="outlined"
                                value={newItem.informacion_remolque.arrastre_sin_freno}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_remolque: { ...newItem.informacion_remolque, arrastre_sin_freno: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Arrastre con freno"
                                variant="outlined"
                                value={newItem.informacion_remolque.arrastre_con_freno}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_remolque: { ...newItem.informacion_remolque, arrastre_con_freno: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                            </Box>
                          )}

                          {/* Checkbox de Carga Medidas */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newItem.carga_medidas}
                                onChange={(e) => setNewItem({ ...newItem, carga_medidas: e.target.checked })}
                              />
                            }
                            label="¿Tiene medidas de carga?"
                          />

                          {/* Campos de información de carga */}
                          {newItem.carga_medidas && (
                            <Box>
                              <TextField
                                fullWidth
                                label="Largo"
                                variant="outlined"
                                value={newItem.informacion_carga.largo}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_carga: { ...newItem.informacion_carga, largo: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Ancho"
                                variant="outlined"
                                value={newItem.informacion_carga.ancho}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_carga: { ...newItem.informacion_carga, ancho: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Alto"
                                variant="outlined"
                                value={newItem.informacion_carga.alto}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_carga: { ...newItem.informacion_carga, alto: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Volumen"
                                variant="outlined"
                                value={newItem.informacion_carga.volumen}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_carga: { ...newItem.informacion_carga, volumen: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                fullWidth
                                label="Capacidad de carga"
                                variant="outlined"
                                value={newItem.informacion_carga.capacidad_carga}
                                onChange={(e) =>
                                  setNewItem({ ...newItem, informacion_carga: { ...newItem.informacion_carga, capacidad_carga: e.target.value } })}
                                sx={{ mb: 2 }}
                              />
                            </Box>
                          )}

                          <Grid mt={3} container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                component="label"
                                sx={{ width: '100%', mb: 2, color: '#FFFFFF' }}
                              >
                                Subir Imagen
                                <input
                                  type="file"
                                  hidden
                                  onChange={handleImageChange}
                                />
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                sx={{ width: '100%', mb: 2, backgroundColor: '#28a745', color: '#FFFFFF' }}
                              >
                                Agregar Item
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Card>

                  {editItemId && editItem && (
                    <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '25px' }}>
                      <Box sx={{ mt: 4 }}>
                        <Typography sx={{ mt: 2 }} variant="h6">Editando Item: {editItem.nombre}</Typography>

                        <FormControl fullWidth sx={{ mb: 2, minHeight: '48px' }}>
                          <InputLabel>Categoría</InputLabel>
                          <Select
                            value={editItem.categoria}
                            onChange={(e) => setEditItem({ ...editItem, categoria: e.target.value })}
                            label="Categoría"
                          >
                            <MenuItem value=""><em>Ninguna</em></MenuItem>
                            <MenuItem value="Coches - SUV">Coches - SUV</MenuItem>
                            <MenuItem value="Furgonetas pasajeros">Furgonetas pasajeros</MenuItem>
                            <MenuItem value="Furgones y camiones">Furgones y camiones</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          fullWidth
                          label="Segmento"
                          variant="outlined"
                          value={editItem.segmento}
                          onChange={(e) => setEditItem({ ...editItem, segmento: e.target.value })}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label="Nombre"
                          variant="outlined"
                          value={editItem.nombre}
                          onChange={(e) => setEditItem({ ...editItem, nombre: e.target.value })}
                          sx={{ mb: 2 }}
                        />
                        {/* Parte 1 del Link */}
                        <TextField
                                fullWidth
                                label="Parte 1 del Link de Karve (category ID)"
                                variant="outlined"
                                value={editItem.parte1}
                                onChange={(e) => {
                                  const parte1 = e.target.value;
                                  const link_reserva = `&initial-category-filter-ids=${parte1}&force-car-type-id=${editItem.parte2}`;
                                  setEditItem({ ...editItem, parte1, link_reserva });
                                }}
                                sx={{ mb: 2 }}
                              />

                              {/* Parte 2 del Link */}
                              <TextField
                                fullWidth
                                label="Parte 2 del Link de karve (car type ID)"
                                variant="outlined"
                                value={editItem.parte2}
                                onChange={(e) => {
                                  const parte2 = e.target.value;
                                  const link_reserva = `&initial-category-filter-ids=${editItem.parte1}&force-car-type-id=${parte2}`;
                                  setEditItem({ ...editItem, parte2, link_reserva });
                                }}
                                sx={{ mb: 2 }}
                              />

                              {/* Link de Reserva generado */}
                              <TextField
                                fullWidth
                                label="Link de Reserva en Karve"
                                variant="outlined"
                                value={editItem.link_reserva}
                                disabled
                                sx={{ mb: 2 }}
                              />
                        {/* Características */}
                        <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Características</Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Plazas"
                              variant="outlined"
                              value={editItem.caracteristicas.plazas}
                              onChange={(e) => setEditItem({ ...editItem, caracteristicas: { ...editItem.caracteristicas, plazas: e.target.value } })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Maletas"
                              variant="outlined"
                              value={editItem.caracteristicas.maletas}
                              onChange={(e) => setEditItem({ ...editItem, caracteristicas: { ...editItem.caracteristicas, maletas: e.target.value } })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Combustible"
                              variant="outlined"
                              value={editItem.caracteristicas.combustible}
                              onChange={(e) => setEditItem({ ...editItem, caracteristicas: { ...editItem.caracteristicas, combustible: e.target.value } })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Transmisión"
                              variant="outlined"
                              value={editItem.caracteristicas.transmision}
                              onChange={(e) => setEditItem({ ...editItem, caracteristicas: { ...editItem.caracteristicas, transmision: e.target.value } })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Etiqueta"
                              variant="outlined"
                              value={editItem.caracteristicas.etiqueta}
                              onChange={(e) => setEditItem({ ...editItem, caracteristicas: { ...editItem.caracteristicas, etiqueta: e.target.value } })}
                            />
                          </Grid>
                        </Grid>

                        {/* Equipamiento */}
                        <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Equipamiento</Typography>
                        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              label="Añadir equipamiento"
                              variant="outlined"
                              value={newEquipamiento}
                              onChange={(e) => setNewEquipamiento(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button sx={{ color: '#FFFFFF' }} fullWidth variant="contained" color="primary" onClick={handleEquipamientoAdd}>
                              Añadir
                            </Button>
                          </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {editItem.equipamiento.map((eq, index) => (
                            <Chip
                              key={index}
                              label={eq}
                              onDelete={() => handleEquipamientoDelete(index)}
                              sx={{ mb: 1 }}
                              color="primary"
                              deleteIcon={<Delete />}
                            />
                          ))}
                        </Box>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editItem.remolque}
                              onChange={(e) => setEditItem({ ...editItem, remolque: e.target.checked })}
                            />
                          }
                          label="¿Lleva remolque?"
                        />

                        {editItem.remolque && (
                          <Box>
                            <TextField
                              fullWidth
                              label="Carnet necesario"
                              variant="outlined"
                              value={editItem.informacion_remolque.carnet}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_remolque: { ...editItem.informacion_remolque, carnet: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Peso del vehículo"
                              variant="outlined"
                              value={editItem.informacion_remolque.peso_vehiculo}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_remolque: { ...editItem.informacion_remolque, peso_vehiculo: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Masa máxima admisible"
                              variant="outlined"
                              value={editItem.informacion_remolque.masa_maxima_admisible}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_remolque: { ...editItem.informacion_remolque, masa_maxima_admisible: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Arrastre sin freno"
                              variant="outlined"
                              value={editItem.informacion_remolque.arrastre_sin_freno}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_remolque: { ...editItem.informacion_remolque, arrastre_sin_freno: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Arrastre con freno"
                              variant="outlined"
                              value={editItem.informacion_remolque.arrastre_con_freno}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_remolque: { ...editItem.informacion_remolque, arrastre_con_freno: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                          </Box>
                        )}

                        {/* Checkbox de Carga Medidas */}
                        <FormControlLabel
                          mb={2}
                          control={
                            <Checkbox
                              checked={editItem.carga_medidas}
                              onChange={(e) => setEditItem({ ...editItem, carga_medidas: e.target.checked })}
                            />
                          }
                          label="¿Tiene medidas de carga?"
                        />

                        {/* Campos de información de carga */}
                        {editItem.carga_medidas && editItem.informacion_carga && (
                          <Box>
                            <TextField
                              fullWidth
                              label="Largo"
                              variant="outlined"
                              value={editItem.informacion_carga.largo}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_carga: { ...editItem.informacion_carga, largo: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Ancho"
                              variant="outlined"
                              value={editItem.informacion_carga.ancho}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_carga: { ...editItem.informacion_carga, ancho: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Alto"
                              variant="outlined"
                              value={editItem.informacion_carga.alto}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_carga: { ...editItem.informacion_carga, alto: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Volumen"
                              variant="outlined"
                              value={editItem.informacion_carga.volumen}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_carga: { ...editItem.informacion_carga, volumen: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Capacidad de carga"
                              variant="outlined"
                              value={editItem.informacion_carga.capacidad_carga}
                              onChange={(e) =>
                                setEditItem({ ...editItem, informacion_carga: { ...editItem.informacion_carga, capacidad_carga: e.target.value } })}
                              sx={{ mb: 2 }}
                            />
                          </Box>
                        )}
                        <Grid mt={2} container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              component="label"
                              sx={{ width: '100%', mb: 2, color: '#FFFFFF' }}
                            >
                              Cambiar Imagen
                              <input
                                type="file"
                                hidden
                                onChange={handleEditImageChange}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => handleEdit(editItemId)}
                              sx={{ width: '100%', mb: 2, color: '#FFFFFF' }}
                            >
                              Guardar Cambios
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={handleCancelEdit}
                              sx={{ backgroundColor: '#dc3545', color: '#FFFFFF' }}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  )}

                  <CustomTable columns={vehicleColumns} data={vehicleTableData} />
                </>
              )}

              {section === 'clientes' && (
                <>
                  <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '15px' }}>
                    <Accordion sx={{ boxShadow: 'none' }}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Añadir cliente</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ mb: 4, width: '100%', height: '100%' }}>
                          <TextField
                            fullWidth
                            label="Nombre"
                            variant="outlined"
                            value={newCliente.nombre}
                            onChange={(e) => setNewCliente({ ...newCliente, nombre: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Teléfono"
                            variant="outlined"
                            value={newCliente.tel}
                            onChange={(e) => setNewCliente({ ...newCliente, tel: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            value={newCliente.mail}
                            onChange={(e) => setNewCliente({ ...newCliente, mail: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Mensaje"
                            variant="outlined"
                            value={newCliente.message}
                            onChange={(e) => setNewCliente({ ...newCliente, message: e.target.value })}
                            sx={{ mb: 2 }}
                          />

                          <Grid mt={3} container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                sx={{ width: '100%', mb: 2, backgroundColor: '#28a745', color: '#FFFFFF' }}
                              >
                                Agregar Cliente
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Card>

                  {editClienteId && editCliente && (
                    <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '25px' }}>
                      <Box sx={{ mt: 4 }}>
                        <Typography sx={{ mt: 2 }} variant="h6">Editando Cliente: {editCliente.nombre}</Typography>

                        <TextField
                          fullWidth
                          label="Nombre"
                          variant="outlined"
                          value={editCliente.nombre}
                          onChange={(e) => setEditCliente({ ...editCliente, nombre: e.target.value })}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label="Teléfono"
                          variant="outlined"
                          value={editCliente.tel}
                          onChange={(e) => setEditCliente({ ...editCliente, tel: e.target.value })}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label="Email"
                          variant="outlined"
                          value={editCliente.mail}
                          onChange={(e) => setEditCliente({ ...editCliente, mail: e.target.value })}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label="Mensaje"
                          variant="outlined"
                          value={editCliente.message}
                          onChange={(e) => setEditCliente({ ...editCliente, message: e.target.value })}
                          sx={{ mb: 2 }}
                        />

                        <Grid mt={2} container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => handleEdit(editClienteId)}
                              sx={{ width: '100%', mb: 2, color: '#FFFFFF' }}
                            >
                              Guardar Cambios
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={handleCancelEdit}
                              sx={{ backgroundColor: '#dc3545', color: '#FFFFFF' }}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  )}

                  <CustomTable columns={clienteColumns} data={clienteTableData} />
                </>
              )}
              {section === 'clientesDTO' && (
  <>
    <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '15px' }}>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Añadir clienteDTO</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Box sx={{ mb: 4, width: '100%', height: '100%' }}>
            <TextField
              fullWidth
              label="Nombre"
              variant="outlined"
              value={newClienteDTO.nombre}
              onChange={(e) => setNewClienteDTO({ ...newClienteDTO, nombre: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Teléfono"
              variant="outlined"
              value={newClienteDTO.tel}
              onChange={(e) => setNewClienteDTO({ ...newClienteDTO, tel: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={newClienteDTO.mail}
              onChange={(e) => setNewClienteDTO({ ...newClienteDTO, mail: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Mensaje"
              variant="outlined"
              value={newClienteDTO.message}
              onChange={(e) => setNewClienteDTO({ ...newClienteDTO, message: e.target.value })}
              sx={{ mb: 2 }}
            />

            <Grid mt={3} container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAdd}
                  sx={{ width: '100%', mb: 2, backgroundColor: '#28a745', color: '#FFFFFF' }}
                >
                  Agregar ClienteDTO
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Card>

    {editClienteDTOId && editClienteDTO && (
      <Card sx={{ marginBottom: '40px', borderRadius: '12px', padding: '25px' }}>
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mt: 2 }} variant="h6">Editando ClienteDTO: {editClienteDTO.nombre}</Typography>

          <TextField
            fullWidth
            label="Nombre"
            variant="outlined"
            value={editClienteDTO.nombre}
            onChange={(e) => setEditClienteDTO({ ...editClienteDTO, nombre: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            value={editClienteDTO.tel}
            onChange={(e) => setEditClienteDTO({ ...editClienteDTO, tel: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={editClienteDTO.mail}
            onChange={(e) => setEditClienteDTO({ ...editClienteDTO, mail: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Mensaje"
            variant="outlined"
            value={editClienteDTO.message}
            onChange={(e) => setEditClienteDTO({ ...editClienteDTO, message: e.target.value })}
            sx={{ mb: 2 }}
          />

          <Grid mt={2} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleEdit(editClienteDTOId)}
                sx={{ width: '100%', mb: 2, color: '#FFFFFF' }}
              >
                Guardar Cambios
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleCancelEdit}
                sx={{ backgroundColor: '#dc3545', color: '#FFFFFF' }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    )}

    <CustomTable columns={clienteDTOColumns} data={clienteDTOTableData} />
  </>
)}

            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default Dashboard;
