import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Typography, Box } from '@mui/material';
import Logo from 'assets/images/LogoAina.svg';

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login.php', { username, password });
      const { token, csrf_token, csrf_token_id } = response.data;
      localStorage.setItem('csrf_token', csrf_token);
      localStorage.setItem('csrf_token_id', csrf_token_id);
      setToken(token);
      setMessage('Login successful');
      navigate('/dashboard');
    } catch (error) {
      setMessage('Login failed');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'red',
      }}
    >
      <Box sx={{ mb: 5 }}>
        <img src={Logo} alt="Logo" style={{ width: '300px' }} />
      </Box>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography variant="h5" sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Login
            </Button>
          </form>
          {message && (
            <Typography color="error" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
