import React, { useState } from 'react';
import { TextField, Button,Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
  '& label': {
    color: '#FFFFFF',
  },
  '& label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
      borderWidth: '2px',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
    color: '#FFFFFF',
  },
});

const ReservationForm = ({ onSubmit, responseMessage }) => {
  const [formData, setFormData] = useState({ nombre: '', email: '', tel: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={(e) => onSubmit(e, formData)} style={{ width: '100%' }}>
      <CustomTextField
        name="nombre"
        label="Nombre"
        type="text"
        fullWidth
        margin="normal"
        required
        value={formData.nombre}
        onChange={handleChange}
      />
      <CustomTextField
        name="email"
        label="Email"
        type="email"
        fullWidth
        margin="normal"
        required
        value={formData.email}
        onChange={handleChange}
      />
      <CustomTextField
        name="tel"
        label="Teléfono"
        type="tel"
        fullWidth
        margin="normal"
        required
        value={formData.tel}
        onChange={handleChange}
      />
      <CustomTextField
        name="message"
        label="Mensaje"
        multiline
        rows={4}
        fullWidth
        margin="normal"
        required
        value={formData.message}
        onChange={handleChange}
      />
      <Button 
        type="submit" 
        sx={{ marginTop: 2, width: '100%', boxShadow: 'none', color: '#031b27' }}
        variant="contained" 
        color="white"  
        startIcon={<MailIcon />}
      >
        Solicitar
      </Button>
      {responseMessage && <Typography variant="body2" color="error">{responseMessage}</Typography>}
    </form>
  );
};

export default ReservationForm;
