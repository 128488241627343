import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Container, Button, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import expocar01 from 'assets/images/ainacar-bola-remolque.png';
import expocar02 from 'assets/images/ainacar-coches-monovolumenes.png';
import expocar03 from 'assets/images/ainacar-coches-suv.png';
import expocar04 from 'assets/images/ainacar-furgonetas-camiones (1).png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const NextArrow = ({ className, style, onClick }) => (
  <ArrowForwardIos
    className={className}
    style={{ 
      ...style, 
      display: 'block', 
      color: '#d6061e', 
      fontSize: '3rem', 
      fontWeight: 'bold',
      width: '40px', 
      height: '40px'
    }}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <ArrowBackIos
    className={className}
    style={{ 
      ...style, 
      display: 'block', 
      color: '#d6061e', 
      fontSize: '3rem', 
      fontWeight: 'bold',
      width: '40px', 
      height: '40px'
    }}
    onClick={onClick}
  />
);

const CustomCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();


  const { t, i18n } = useTranslation();



  const images = [
    { src: expocar02, text: 'COCHES y MONOVOLÚMENES', texttitle: t('una_flota_variada_coches'), text2: t('una_flota_variada_coches_text'), button: 'Botón 1' },
    { src: expocar03, text: 'SUV',  texttitle: t('una_flota_variada_suv'), text2: t('una_flota_variada_coches_text'), button: 'Botón 2' },
    { src: expocar04, text: 'FURGONETAS y CAMIONES',  texttitle: t('una_flota_variada_furgonetas'), text2: t('una_flota_variada_coches_text'), button: 'Botón 3' },
    { src: expocar01, text: 'VEHÍCULOS CON BOLA DE REMOLQUE',  texttitle: t('una_flota_variada_bola'), text2: t('una_flota_variada_coches_text'), button: 'Botón 4' },
  ];



  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <Container sx={{ mt: 0, minHeight: '550px', position: 'relative' }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              mt: 10,
              mb: 0,
              position: 'relative',
              width: '100%',
              height: '450px',
              transition: 'all 0.5s ease',
              transform: index === currentSlide ? 'scale(1.5)' : 'scale(1)',
              padding: index === currentSlide ? '0 10%' : '0 14%',
            }}
          >
            <img 
              src={image.src} 
              alt={`Slide ${index}`}
              style={{ width: '100%', height: 'auto' }}
            />
            <Box sx={{minHeight: '100px'}}>
            {index === currentSlide && (
              <Box
                sx={{
                  position: 'relative',
                  marginTop: '-10px',
                  textAlign: 'center',
                  transform: 'scale(0.7)',
                  transition: 'all 0.5s ease',
                }}
              >
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {image.texttitle}
                </Typography>
                <Typography variant="body2" component="div" sx={{ mb: 2 }}>
                  {image.text2}
                </Typography>
            
                <Button onClick={() => navigate('/flota')} variant="contained" sx={{backgroundColor:"#d6061e", color: '#FFFFFF'}}>
                  Ver Flota
                </Button>
              </Box>
            )}
          </Box>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default CustomCarousel;
