import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';

const Whatsapp = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({ nombre: '', email: '', tel: '', message: '' });
  const [responseMessage, setResponseMessage] = useState('');
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  const token = 'mi-token-estatico';

  const icons = [
    { component: <WhatsAppIcon style={{ color: '#FFFFFF', width: '60%', height: '60%' }} />, color: '#25D366' },
    { component: <PhoneIcon style={{ color: '#FFFFFF', width: '60%', height: '60%' }} />, color: '#0078FF' },
    { component: <MailIcon style={{ color: '#FFFFFF', width: '60%', height: '60%' }} />, color: '#FF0000' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex(prevIndex => (prevIndex + 1) % icons.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=34658962613&text=Voldria%20llogar%20un%20vehicle', '_blank');
  };

  const handleCallClick = () => {
    window.open('tel:+34658962613', '_blank');
  };

  const handleMailSubmit = (event) => {
    event.preventDefault();
    sendData();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendData = async () => {
    try {
      const response = await fetch(`/api/public/clientes?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      setResponseMessage('enviado correctamente');
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      setResponseMessage('Error al enviar los datos');
    }
  };

  return (
    <>
      <Box sx={{ position: 'fixed', top: '90%', right: '2%', zIndex: '1001', transition: '0.15s linear' }}>
        <Box
          onClick={handleClick}
          sx={{
            width: { xs: 40, md: 40, lg: 55 },
            height: { xs: 40, md: 40, lg: 55 },
            borderRadius: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: icons[currentIconIndex].color,
            cursor: 'pointer',
            transition: 'background-color 3s ease, transform 3s ease',
            transform: 'scale(1)',
            '&:hover': {
              transform: 'scale(1.1)',
            }
          }}
        >
          {icons[currentIconIndex].component}
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            maxWidth: '300px',
          }
        }}
      >
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', width: '100%' }}>
          <Box 
            onClick={handleWhatsAppClick}
            sx={{ 
              mb: 1, 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center', 
              backgroundColor: '#25D366', 
              color: '#FFFFFF', 
              borderRadius: 2, 
              p: 1, 
              cursor: 'pointer', 
              width: '100%' 
            }}
          >
            <WhatsAppIcon sx={{ color: '#FFFFFF', mr: 1 }} />
            <Typography sx={{ color: '#FFFFFF' }}>WhatsApp</Typography>
          </Box>
          <Box 
            onClick={handleCallClick}
            sx={{ 
              mb: 1, 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center', 
              backgroundColor: '#000', 
              color: '#FFFFFF', 
              borderRadius: 2, 
              p: 1, 
              cursor: 'pointer', 
              width: '100%' 
            }}
          >
            <PhoneIcon sx={{ color: '#FFFFFF', mr: 1 }} />
            <Typography sx={{ color: '#FFFFFF' }}>+34 658 962 613</Typography>
          </Box>
          <Typography variant="h6" sx={{ mb: 0.5, mt: 3 }}>Formulario de Contacto</Typography>
          <form onSubmit={handleMailSubmit} style={{ width: '100%' }}>
            <TextField
              name="nombre"
              label="Nombre"
              type="text"
              fullWidth
              margin="normal"
              required
              value={formData.nombre}
              onChange={handleChange}
            />
            <TextField
              name="email"
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              name="tel"
              label="Teléfono"
              type="tel"
              fullWidth
              margin="normal"
              required
              value={formData.tel}
              onChange={handleChange}
            />
            <TextField
              name="message"
              label="Message"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              required
              value={formData.message}
              onChange={handleChange}
            />
            <Button type="submit" variant="contained" color="primary" startIcon={<MailIcon />}>
              ENVIAR
            </Button>
          </form>
          
          {responseMessage && <Typography variant="body2" color="error">{responseMessage}</Typography>}
        </Box>
      </Popover>
    </>
  );
};

export default Whatsapp;






/*
import React from 'react';
import Box from '@mui/material/Box';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



const Whatsapp = () => {
  
    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=34658962613&text=Voldria%20llogar%20un%20vehicle', '_blank'); // Reemplaza con el enlace deseado
      };
  return (
    <>
      <Box sx={{ position: 'fixed', top: '90%', right: '2%', zIndex: '1001', transition: '0.15s linear' }}>
      <Box 
      onClick={handleClick}
      sx={{
        width: {xs:40, md: 40, lg:55}, // Ancho del círculo
        height: {xs:40, md: 40, lg:55}, // Altura del círculo
        borderRadius: '30%', // Esto hace que el Box sea circular
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#25D366', // Color de fondo similar al de WhatsApp
        cursor: 'pointer'
      }}
    >
      <WhatsAppIcon style={{ color: 'white', width: '60%', // Ancho del círculo
        height: '60%', // Altura del círculo
         }} />
    </Box>
      </Box>
      
    </>
  );
};

export default Whatsapp;*/
